body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root{
  --background-color: var(--light);
  --dark-accent: var(--gray-dark);
  --background-color-secondary: #e0e0e0;
  --highlight-color: #f88550;
  --white-text: white;
  --passed-color: #79e479;
  --font-color: #1b1b1b;
}


body{
  background-color: var(--light);
  background-color: var(--background-color);
  /* background-image: linear-gradient(180deg, #FFFFFF 0%, var(--background-color) 100%); */
  background-repeat: no-repeat;

  text-align: center;
  color: var(--gray-dark);
  color: var(--dark-accent);
  font-size: 1.25em;

  min-height: 50em;

  color: #1b1b1b;

  color: var(--font-color);
}


.App{
  width: 100%;
  margin: 0 auto 3em;
}

.app-body{
  width: 90%;
  margin: 0 auto;
}
/* TEST CSS */




#copy-link{
  width: 90%;
  height: 5em;
  margin: 0 auto;
}

a{
  color: #f88550;
  color: var(--highlight-color);
}


.text-box{
  text-align: left;
  padding: .25em 1em;
  margin-top: 1em;
  background-color: #e0e0e0;
  background-color: var(--background-color-secondary);
  font-size: 1.25em;

  border-radius: 8px;
  box-shadow: 2px 2px 10px var(--gray-dark) inset;
  box-shadow: 2px 2px 10px var(--dark-accent) inset;
}



textarea{
  font-size: 1.5em;
  width: 100%;
}




.tutorial-section h1{
  display: inline;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}


.landing-box{
    padding: .5em 1em;
    margin-top:  1em;
    box-shadow: 2px 2px 5px var(--dark-accent) inset;
    border-radius: 8px;
    background-color: var(--background-color-secondary);
}

.tutorial-container{
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.tutorial-banner{
    display: grid;
    grid-template-columns: 4em auto;
    text-align: left;
    margin: 1em auto;

    cursor: pointer;

}

.tutorial-banner-element{
    box-shadow: 2px 2px 5px var(--dark-accent);
}

.tutorial-active-indicator{
    /* margin: 0;
    font-size: 3em; */
    text-align: center;
    font-weight: 900;
    background-color: var(--dark-accent);
    color: var(--white-text);

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@-webkit-keyframes slide-open {
    0%{
        color: whitesmoke;
        max-height: 0;
    }

    50%{
        /* color: whitesmoke; */
        max-height: 250em;
    }
    
    100%{
        color: var(--dark-accent);
    }
}

@keyframes slide-open {
    0%{
        color: whitesmoke;
        max-height: 0;
    }

    50%{
        /* color: whitesmoke; */
        max-height: 250em;
    }
    
    100%{
        color: var(--dark-accent);
    }
}

.content-container{
    box-shadow: 2px 2px 20px var(--dark-accent) inset;
    background-color: whitesmoke;
    border-radius: 8px;
    padding: .25em 0.5em 1em 1.5em;

    -webkit-animation: slide-open 2s forwards;

            animation: slide-open 2s forwards;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}



.tutorial-banner-title{
    padding-left: 1em;
    background-color: var(--background-color-secondary);
}

.tutorial-section-heading{
    background-color: var(--background-color-secondary);
}

.toggle-expand{
    background-color: var(--dark-accent);
    color: var(--white-text);
}



.heading-container{
    margin: 0;
    padding-bottom: 0.25em;
    border-bottom: 1px solid var(--dark-accent);
}

.heading-container h2{
    margin: 1em 0 0;
}

.heading-container hr{
    color: red;
}


.embeded-video{
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
}
input, select, textarea, button{
    border-radius: 4px;
    border: none;

    font-size: 1.5em;
    
    margin: 0.25em 0;

    color: var(--dark-accent);
}

textarea{
    display: block;
    resize: none;
    padding: .66em .5em;
    min-height: 5em;
    box-shadow: 2px 2px 10px var(--dark-accent);
    color: var(--white-text);
    font-size: 1em;
    background-color: var(--dark-accent);
}





input[type="submit"], #assignment-container, button{
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--dark-accent);
    
    padding: 0.25em;
    margin: 1em;
    box-shadow: 2px 2px 8px var(--dark-accent);
    
    cursor: pointer;
}

input[type="submit"]:hover, #assignment-container, button:hover{
    color: var(--highlight-color);
    border: 2px solid var(--highlight-color);
    box-shadow: 5px 5px 20px var(--dark-accent);
}


input[type = 'checkbox']{
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
    margin-right: .5em;
}





input[type="text"], input[type="number"]{
    box-shadow: 2px 2px 8px var(--dark-accent);
    text-align: center;
}

select{
    cursor: pointer;
    box-shadow: 2px 2px 8px var(--dark-accent);
}

label{
    font-size: 1.5em;
    margin-bottom: 1em;
}


.copy-text-button{
    font-size: .75em;
}

.assignment-form-section, .section-box{
    padding: 0 0 .66em;
    width: 66%;
    min-width: 300px;
    margin: 1em auto;
    
    border-radius: 10px;
    box-shadow: 2px 2px 10px var(--dark-accent) inset;
    background-color: var(--background-color-secondary);
}


.assignment-form-section p{
    margin: .5em;
}

.new-assignment-section-heading, .section-heading{
    margin: 0;
    padding: 0 0 .25em 0.25em;
    background-color: var(--dark-accent);
    color: var(--background-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.in-text-link{
    color: var(--highlight-color);
}



#assignment-container{
    background-color: var(--background-color);
    text-align: center;
    padding: 2em;
    width: 100%;
    margin: 2em auto;
    border-radius: 4px;
    border: none;
    box-shadow: 2px 2px 20px var(--dark-accent) inset;
    cursor: auto;

    color: var(--dark-accent);
}


.nf-assignment-score{
    box-shadow: 2px 2px 20px #a08e7a;
    height: 30em;
}

.nf-answer-score{
    display: hidden;
}


#submit-assignment, #error-navigation-buttons{
    background-color: var(--background-color);
    color: var(--dark-accent);
    
    margin: 0 auto;
}


#error-navigation-buttons h2, #sign-and-submit-form h2{
    color: var(--white-text);
    background-color: var(--dark-accent);
    padding: .25em 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    margin: 0;
}


.assignment-score-passed{
    box-shadow: 2px 2px 40px var(--passed-color);
}

.encrypted-token{
    padding: 1em;
}
.token-info h4{
    padding: 0.25em 1em;
}

.nav-banner{
    background-color: var(--dark-accent);
}

.nav-desktop a {
    color: var(--white-text);
    border: 2px solid var(--white-text);

    text-decoration: none;
    font-weight: 600;

    border-radius: 8px;
    padding: .66em;
}

.nav-mobile .nav-button{

    margin: 1em;
}

.nav-mobile .nav-button a{
    color: var(--white-text);
    font-weight: 600;
    text-transform: uppercase;
}

.nav-mobile hr{
    border-color: var(--white-text); 
}

.hamburger-icon{
    margin: .25em 0;
}


.nav-desktop{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  
    width: 100%;
    margin: 0 auto;
    padding: 2.5em 0;
    text-align: center;
}

.nav-mobile{
    display: none;
    border-bottom-right-radius: 10px; 
    border-bottom-left-radius: 10px; 
    width: 97%;
    margin: 0 auto;

    box-shadow: 0 5px 20px var(--dark-accent);
}

.nav-desktop a:hover{
    color: var(--highlight-color);
    border-color: var(--highlight-color);
}



@media only screen and (max-width: 1000px){
    .nav-desktop{
        display: none;
    }

    .nav-mobile{
        display: grid;
        grid-template-columns: 1fr;
    }
}



@media only screen and (max-width: 1000px){
    body{
        font-size: 1.1em;
    }
    
    /* .nav-button{
        margin: 1em 0;
    } */
    /* #main-nav-banner{
        grid-template-columns: 1fr; 
        grid-gap: 1em;
    } */
    .tutorial-container{
        width: 100%;
        margin: 0 auto;
    }

    

    input[type = 'checkbox']{
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        padding: 10px;
        margin-right: .5em;
    }
}

@media only screen and (max-width: 800px){
    body{
        font-size: 1em;
    }
    
    .information-heading{
        font-size: 1.5em;
        text-align: center;
    }

    .information-paragraph{
        text-align: center;
    }

    .assignment-form-section{
        width: 100%
    }

    input[type = 'checkbox']{
        /* Double-sized Checkboxes */
        -ms-transform: scale(1); /* IE */
        -moz-transform: scale(1); /* FF */
        -webkit-transform: scale(1); /* Safari and Chrome */
        -o-transform: scale(1); /* Opera */
        padding: 10px;
        margin-right: .25em;
    }
}

@media only screen and (max-width: 400px){
    body{
        font-size: 0.75em;
    } 

    .text-box{
        text-align: center;
    }

}



