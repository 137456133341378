

.landing-box{
    padding: .5em 1em;
    margin-top:  1em;
    box-shadow: 2px 2px 5px var(--dark-accent) inset;
    border-radius: 8px;
    background-color: var(--background-color-secondary);
}

.tutorial-container{
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.tutorial-banner{
    display: grid;
    grid-template-columns: 4em auto;
    text-align: left;
    margin: 1em auto;

    cursor: pointer;

}

.tutorial-banner-element{
    box-shadow: 2px 2px 5px var(--dark-accent);
}

.tutorial-active-indicator{
    /* margin: 0;
    font-size: 3em; */
    text-align: center;
    font-weight: 900;
    background-color: var(--dark-accent);
    color: var(--white-text);

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@keyframes slide-open {
    0%{
        color: whitesmoke;
        max-height: 0;
    }

    50%{
        /* color: whitesmoke; */
        max-height: 250em;
    }
    
    100%{
        color: var(--dark-accent);
    }
}

.content-container{
    box-shadow: 2px 2px 20px var(--dark-accent) inset;
    background-color: whitesmoke;
    border-radius: 8px;
    padding: .25em 0.5em 1em 1.5em;

    animation: slide-open 2s forwards;
    animation-timing-function: ease-out;
}



.tutorial-banner-title{
    padding-left: 1em;
    background-color: var(--background-color-secondary);
}

.tutorial-section-heading{
    background-color: var(--background-color-secondary);
}

.toggle-expand{
    background-color: var(--dark-accent);
    color: var(--white-text);
}



.heading-container{
    margin: 0;
    padding-bottom: 0.25em;
    border-bottom: 1px solid var(--dark-accent);
}

.heading-container h2{
    margin: 1em 0 0;
}

.heading-container hr{
    color: red;
}


.embeded-video{
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
}