
.assignment-form-section, .section-box{
    padding: 0 0 .66em;
    width: 66%;
    min-width: 300px;
    margin: 1em auto;
    
    border-radius: 10px;
    box-shadow: 2px 2px 10px var(--dark-accent) inset;
    background-color: var(--background-color-secondary);
}


.assignment-form-section p{
    margin: .5em;
}

.new-assignment-section-heading, .section-heading{
    margin: 0;
    padding: 0 0 .25em 0.25em;
    background-color: var(--dark-accent);
    color: var(--background-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.in-text-link{
    color: var(--highlight-color);
}