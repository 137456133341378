:root{
  --background-color: var(--light);
  --dark-accent: var(--gray-dark);
  --background-color-secondary: #e0e0e0;
  --highlight-color: #f88550;
  --white-text: white;
  --passed-color: #79e479;
  --font-color: #1b1b1b;
}


body{
  background-color: var(--background-color);
  /* background-image: linear-gradient(180deg, #FFFFFF 0%, var(--background-color) 100%); */
  background-repeat: no-repeat;

  text-align: center;
  color: var(--dark-accent);
  font-size: 1.25em;

  min-height: 50em;

  color: var(--font-color);
}


.App{
  width: 100%;
  margin: 0 auto 3em;
}

.app-body{
  width: 90%;
  margin: 0 auto;
}
/* TEST CSS */




#copy-link{
  width: 90%;
  height: 5em;
  margin: 0 auto;
}

a{
  color: var(--highlight-color);
}


.text-box{
  text-align: left;
  padding: .25em 1em;
  margin-top: 1em;
  background-color: var(--background-color-secondary);
  font-size: 1.25em;

  border-radius: 8px;
  box-shadow: 2px 2px 10px var(--dark-accent) inset;
}



textarea{
  font-size: 1.5em;
  width: 100%;
}




.tutorial-section h1{
  display: inline;
  transform: rotate(45deg);
}